<app-landing-page-section>
  <app-landing-page-section-content>
    <header class="mat-headline-5">About Quantis databases</header>
    <app-landing-page-section-content-two-columns>
      <ng-container col1>
        <img
          class="logo"
          ngSrc="assets/images/wfldb.png"
          alt="WFLDB logo"
          height="180"
          width="180"
        />
        <ul class="plus-list">
          <li>
            <strong>3800+ datasets</strong> for various agricultural commodities
            (annual and perennial crops, animal products) and ag. inputs (e.g.,
            fertilizers), covering various geographies and management practices
          </li>
          <li>
            <strong>2300+ sub-datasets</strong> used to model farm activities
            (e.g. irrigation, machinery) or food processing
          </li>
          <li>
            <strong>7000+ sub-datasets</strong> associated with land use change
          </li>
        </ul>
      </ng-container>
      <ng-container col2>
        <img
          class="logo"
          ngSrc="assets/images/waldb.png"
          alt="WALDB logo"
          height="180"
          width="180"
        />
        <ul class="plus-list">
          <li>
            <strong>1000+ datasets</strong>, including fiber production, yarn,
            fabric, finishing, leather, shoe production, etc.
          </li>
          <li>
            <strong>50+ different processing steps</strong> identified along the
            apparel/footwear supply chain
          </li>
          <li>
            Datasets include <strong>raw materials and apparel</strong> and
            <strong>footwear production</strong>, for both use and end-of-life
            stages
          </li>
        </ul>
      </ng-container>
    </app-landing-page-section-content-two-columns>
  </app-landing-page-section-content>
</app-landing-page-section>
