<app-landing-page-section>
  <app-landing-page-section-content class="main">
    <header class="mat-headline-4">Custom</header>
    <p>
      Leverage the full potential of eQosphere to tailor emission factors
      specific to your supply chain, setting a solid foundation for tracking
      change.
    </p>
    <button mat-raised-button color="primary" (click)="onCustomDatasets()">
      <strong>Customize datasets</strong>
    </button>
  </app-landing-page-section-content>
  <app-landing-page-section-content class="description">
    <app-landing-page-section-content-two-columns>
      <ng-container col1>
        <mat-icon svgIcon="crop-mix"></mat-icon>
        <header class="mat-headline-6">
          Assess your agricultural commodities
        </header>
        <ul class="plus-list">
          <li>Collect data tailored to your agricultural supply chain</li>
          <li>
            Establish a farm baseline to identify hotspots and evaluate benefits
            from intervention
          </li>
          <li>
            Leverage our templates to quantify impacts of land use change, fertilizer use irrigation, ag. machinery, processing, etc.
          </li>
        </ul>
      </ng-container>
      <ng-container col2>
        <mat-icon svgIcon="custom"></mat-icon>
        <header class="mat-headline-6">Customize your emission factors</header>
        <ul class="plus-list">
          <li>Fine-tune datasets to accurately reflect your operations</li>
          <li>
            Represent your value chain with precision, whether adjusting for
            suppliers using green electricity or aligning process yields with
            actual data
          </li>
        </ul>
      </ng-container>
    </app-landing-page-section-content-two-columns>
  </app-landing-page-section-content>
</app-landing-page-section>
