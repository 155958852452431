<app-landing-page-section>
  <app-landing-page-section-content>
    <header class="mat-headline-4">Access</header>
    <mat-form-field class="query" appearance="fill">
      <mat-label>Search datasets</mat-label>
      <input #q type="text" matInput (keydown.enter)="onSearch(q.value)" />
      <button matSuffix mat-icon-button (click)="onSearch(q.value)">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
    <app-database-filter
      class="filter"
      [(value)]="databases"
    ></app-database-filter>
    <app-geography-filter
      class="filter"
      [(value)]="geographies"
    ></app-geography-filter>
    <div class="suggestions dark-theme">
      Try:
      <button
        *ngFor="let suggestion of suggestions"
        mat-stroked-button
        (click)="onSearch(suggestion)"
      >
        {{ suggestion }}
      </button>
    </div>
  </app-landing-page-section-content>
</app-landing-page-section>
